import { Col, Row } from "antd";
import Image from "next/image";
import { TFunction } from "react-i18next";
import styled from "styled-components";
import { Body, Hero } from "sunday-component";
import Button from "../Common/Button";

const ThankyouContainer = styled.div`
  width: 100%;
  padding: 20% 0;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
`;

const ThankyouHero = styled(Hero)`
  margin: 16px 0 8px 0;
`;

const ThankyouButton = styled(Button)`
  margin-top: 40px;
  max-width: 400px;
`;

type DropLeadThankyouProps = {
  isFromCarnival: boolean;
  onClickThankyou: Function;
  t: TFunction<"drop-lead"[]>;
};

const DropLeadThankyou = ({
  isFromCarnival,
  onClickThankyou,
  t,
}: DropLeadThankyouProps) => {
  const mode = isFromCarnival ? "carnival" : "normal";

  return (
    <ThankyouContainer>
      <Image
        width="190px"
        height="160px"
        src="/static/pages/landing/thank-you.svg"
      />
      <ThankyouHero level={4}>{t(`successDialog.${mode}.hero`)}</ThankyouHero>
      <Body level={5}>{t(`successDialog.${mode}.body`)}</Body>
      <Row justify="center">
        <Col xs={24} md={12}>
          <ThankyouButton onClick={onClickThankyou} block>
            {t(`successDialog.${mode}.ok`)}
          </ThankyouButton>
        </Col>
      </Row>
    </ThankyouContainer>
  );
};

export default DropLeadThankyou;
