/* eslint-disable jsx-a11y/anchor-is-valid */
import NextLink from "next/link";
import LinkBody from "./LinkBody";

export type LinkProps = {
  href: string;
  children: string | JSX.Element;
};

const Link = ({ href, children }: LinkProps) => (
  <NextLink href={href} passHref>
    <a target="#!">
      <LinkBody level={7}>{children}</LinkBody>
    </a>
  </NextLink>
);

export default Link;
