import { Tree } from "antd";
import { DataNode, TreeProps } from "antd/lib/tree";
import { color } from "src/styles/globals";
import styled from "styled-components";
import { Body } from "sunday-component";
import { TextBoxLabelText } from "./TextBoxForm";

const StyledTree = styled(Tree)`
  border: 1px solid ${color.charcoal03};
  border-radius: 8px;
  padding-bottom: 16px;
  margin-top: 4px;

  .ant-tree-treenode {
    padding: 16px 16px 0px 16px;
    width: 100%;
  }

  .ant-tree-switcher {
    display: none;
  }

  .ant-tree-checkbox {
    &:hover {
      border-radius: 4px;
    }
    &:active {
      border-radius: 4px;
    }
    &:focus {
      border-radius: 4px;
    }
    &::after {
      border-radius: 4px;
    }
  }

  .ant-tree-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
    border-radius: 4px;
    &:hover {
      border-radius: 4px;
    }
    &:active {
      border-radius: 4px;
    }
    &:focus {
      border-radius: 4px;
    }
  }

  .ant-tree-checkbox:focus-within {
    .ant-tree-checkbox:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-unselected-focused-background-color
        );
        border-color: var(--ui-form-checkboxes-unselected-focused-border-color);
      }
    }

    .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-selected-focused-background-color
        );
        border-color: var(--ui-form-checkboxes-selected-focused-border-color);
        :after {
          border-color: var(--ui-form-checkboxes-selected-focused-icon-color);
        }
      }
    }

    .ant-tree-checkbox-indeterminate:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-indeterminate-focused-background-color
        );
        border-color: var(
          --ui-form-checkboxes-indeterminate-focused-background-color
        );
        :after {
          background-color: var(
            --ui-form-checkboxes-indeterminate-focused-icon-color
          );
        }
      }
    }
  }

  .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: var(--ui-form-checkboxes-unselected-hover-border-color);
    .ant-tree-checkbox:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-unselected-hover-background-color
        );
        border-color: var(--ui-form-checkboxes-unselected-hover-border-color);
      }
    }

    .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-selected-hover-background-color
        );
        border-color: var(--ui-form-checkboxes-selected-hover-background-color);
        :after {
          border-color: var(--ui-form-checkboxes-selected-hover-icon-color);
        }
      }
      :after {
        border-color: var(--ui-form-checkboxes-selected-hover-background-color);
      }
    }

    .ant-tree-checkbox-indeterminate:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-indeterminate-hover-background-color
        );
        :after {
          background-color: var(
            --ui-form-checkboxes-indeterminate-hover-icon-color
          );
        }
      }
    }
  }

  .ant-tree-checkbox:active {
    .ant-tree-checkbox:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-unselected-pressed-background-color
        );
        border-color: var(--ui-form-checkboxes-unselected-pressed-border-color);
      }
    }

    .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-selected-pressed-background-color
        );
        border-color: var(
          --ui-form-checkboxes-selected-pressed-background-color
        );
        :after {
          border-color: var(--ui-form-checkboxes-selected-pressed-icon-color);
        }
      }
    }

    .ant-tree-checkbox-indeterminate:not(.ant-tree-checkbox-disabled) {
      .ant-tree-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-indeterminate-pressed-background-color
        );
        :after {
          background-color: var(
            --ui-form-checkboxes-indeterminate-pressed-icon-color
          );
        }
      }
    }
  }

  .ant-tree-checkbox {
    .ant-tree-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-unselected-enabled-background-color
      );
      border-color: var(--ui-form-checkboxes-unselected-enabled-border-color);
    }
  }

  .ant-tree-checkbox-disabled {
    .ant-tree-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-unselected-disabled-background-color
      );
      border-color: var(
        --ui-form-checkboxes-unselected-disabled-border-color
      ) !important;
    }
  }

  .ant-tree-checkbox-checked {
    .ant-tree-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-selected-enabled-background-color
      );
      border-color: var(--ui-form-checkboxes-selected-enabled-background-color);
      :after {
        border-color: var(--ui-form-checkboxes-selected-enabled-icon-color);
      }
    }
  }

  .ant-tree-checkbox-checked.ant-tree-checkbox-disabled {
    .ant-tree-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-selected-disabled-background-color
      );
      border-color: var(
        --ui-form-checkboxes-selected-disabled-background-color
      ) !important;
      :after {
        border-color: var(--ui-form-checkboxes-selected-disabled-icon-color);
      }
    }
  }

  .ant-tree-checkbox-indeterminate {
    .ant-tree-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-indeterminate-enabled-background-color
      );
      border-color: var(
        --ui-form-checkboxes-indeterminate-enabled-background-color
      );
      :after {
        height: 2px;
        background-color: var(
          --ui-form-checkboxes-indeterminate-enabled-icon-color
        );
      }
    }
  }

  .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled {
    .ant-tree-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-indeterminate-disabled-background-color
      );
      border-color: var(
        --ui-form-checkboxes-indeterminate-disabled-background-color
      ) !important;
      :after {
        background-color: var(
          --ui-form-checkboxes-indeterminate-disabled-icon-color
        );
      }
    }
  }

  .ant-tree-checkbox-checked:after {
    border: 1px solid var(--ui-form-checkboxes-selected-focused-border-color);
  }
`;

export const generateTreeNode = (dataNode: DataNode): DataNode => ({
  ...dataNode,
  title: <Body level={7}>{dataNode.title}</Body>,
});

export const headNodeStyle = {
  marginTop: "16px",
  borderTop: `1px solid ${color.charcoal03}`,
};

const applyStyle = (treeItems: DataNode[]) =>
  treeItems.map((treeItem, index) =>
    index === 0 ? treeItem : { ...treeItem, style: headNodeStyle }
  );

export type TreeFormProps = {
  label: string;
  treeData: DataNode[];
} & TreeProps;

const TreeForm = ({ label, treeData, ...props }: TreeFormProps) => (
  <div>
    <TextBoxLabelText>{label}</TextBoxLabelText>
    <StyledTree
      checkable
      showLine={false}
      selectable={false}
      treeData={applyStyle(treeData)}
      defaultExpandAll
      // without this tree will show dropdown icon
      switcherIcon={<></>}
      {...props}
    />
  </div>
);

export default TreeForm;
