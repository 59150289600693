import { Col, Row } from "antd";
import { Hero as H, Headline as HL, Body as Bd } from "sunday-component";
import styled from "styled-components";
import { useRouter } from "next/router";
import { i18n } from "i18next";
import { down } from "styled-breakpoints";
import { color } from "../../styles/globals";
import USPCard from "./USPCard";
import { DesktopContainer, MobileContainer } from "../Common/Container";

const MainRow = styled(Row)`
  background: ${color.coolGreyCG1};
  padding-top: 80px;
  padding-bottom: 80px;
`;
const MainCol = styled(Col)`
  max-width: 1400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
const Hero = styled(H)`
  text-align: center;
`;
const Headline = styled(HL)`
  text-align: center;
`;
const Body = styled(Bd)`
  text-align: center;
  margin: 16px 0px 80px 0px;
  ${down("md")} {
    margin-bottom: 40px;
  }
`;

export type USPItemProp = {
  title: {
    th: string;
    en: string;
  };
  description: {
    th: string;
    en: string;
  };
  image: string;
};

export type USPListComponentProp = {
  items: USPItemProp[];
  content: any;
};

type Item = {
  enabled: boolean;
  title: i18n;
  body: i18n;
  imageUrl: string;
};

const USPListComponent = ({ items, content }: USPListComponentProp) => {
  const router = useRouter();
  const { locale } = useRouter();
  const itemList: Item[] = Object.values(content.keyUsp02.item);

  return content.keyUsp02.enabled ? (
    <MainRow>
      <Col xs={{ span: 20, offset: 2 }} lg={{ span: 22, offset: 1 }}>
        <Row justify="center">
          <DesktopContainer>
            <Hero level={3}>{content.keyUsp02.title[locale]}</Hero>
          </DesktopContainer>
          <MobileContainer>
            <Headline level={1}>{content.keyUsp02.title[locale]}</Headline>
          </MobileContainer>
        </Row>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }}>
        <Row justify="center">
          <DesktopContainer>
            <Body level={5}>{content.keyUsp02.body[locale]}</Body>
          </DesktopContainer>
          <MobileContainer>
            <Body level={7}>{content.keyUsp02.body[locale]}</Body>
          </MobileContainer>
        </Row>
      </Col>
      <MainCol xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }}>
        <Row justify="center">
          {itemList.map((data) =>
            data.enabled ? (
              <USPCard
                title={data.title[router.locale]}
                description={data.body[router.locale]}
                image={data.imageUrl}
              />
            ) : (
              <></>
            )
          )}
        </Row>
      </MainCol>
    </MainRow>
  ) : (
    <></>
  );
};
export default USPListComponent;
