import { Card as C, Col, Row, Skeleton } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import SkeletonAvatar from "antd/lib/skeleton/Avatar";
import { useRouter } from "next/router";
import { ReactElement, useState } from "react";
import { down, up } from "styled-breakpoints";
import styled from "styled-components";
import { Title as T } from "sunday-component";
import { I18Text } from "src/types/i18Text";
import { LabelType } from "../../types/label";

const Card = styled(C)`
  ${down("sm")} {
    width: auto;
    min-width: 109px;
    height: 192px;
  }
  border-radius: 11px;
  width: 195px;
  height: 236px;
  margin: auto;
`;
const Image = styled.img`
  ${down("sm")} {
    width: 64px;
    height: 64px;
  }
  width: 80px;
  height: 80px;
`;
const CardDetailContainer = styled.div`
  ${down("sm")} {
    margin: 28px 8px 8px 8px;
  }
  align-items: center;
  margin: 36px 16px 20px 16px;
`;

const DesktopTitle = styled(T).attrs({ level: 5 })`
  ${down("sm")} {
    display: none;
  }
  margin: auto;
  text-align: center;
`;
const MobileTitle = styled(T).attrs({ level: 8 })`
  ${up("md")} {
    display: none;
  }
  margin: auto;
  text-align: center;
`;

const CardContainer = styled(Col)`
  ${down("sm")} {
    padding: 4px;
  }
  padding: 8px;
  height: 100%;
`;

const LogoContainer = styled(Row)`
  margin-bottom: 8px;
`;

export type ProductCardProps = {
  isLoading: boolean;
  icon?: string;
  displayName?: I18Text;
  labels?: LabelType[];
};
const ProductCard = ({
  isLoading,
  icon,
  displayName,
  labels,
  ...props
}: ProductCardProps): ReactElement => {
  const { locale } = useRouter();
  const { sm } = useBreakpoint();
  const [isImageError, setIsImageError] = useState<boolean>(false);

  const onImageError = () => {
    setIsImageError(true);
  };

  return (
    <CardContainer {...props}>
      <Card bodyStyle={{ padding: "0px" }}>
        <CardDetailContainer>
          <LogoContainer justify="center">
            {isImageError || isLoading ? (
              <SkeletonAvatar size={sm ? 80 : 64} active />
            ) : (
              <Image src={icon} alt="" onError={onImageError} />
            )}
          </LogoContainer>
          {isLoading ? (
            <Skeleton paragraph={false} />
          ) : (
            <>
              <DesktopTitle>{displayName?.[locale]}</DesktopTitle>
              <MobileTitle>{displayName?.[locale]}</MobileTitle>
            </>
          )}
        </CardDetailContainer>
      </Card>
    </CardContainer>
  );
};
export default ProductCard;
