import { Col, Row } from "antd";
import { ReactElement } from "react";
import { down, up } from "styled-breakpoints";
import styled from "styled-components";
import { Headline, Hero } from "sunday-component";
import Link from "next/link";
import { useRouter } from "next/router";
import ProductCard from "./ProductCard";
import { ProductCategory as ProductCategoryType } from "../../types/productCategory";
import { color } from "../../styles/globals";
import ProductTypes from "../../types/productType";
import {
  embedLocaleLanguageAndEnvToExternalLink,
  filterParams,
  formatPathByLogicalGroupChannel,
} from "../../utils/url";
import { GLOBAL_PARAMS } from "../../constants/params";

const StyledLink = styled.a``;

const Container = styled(Row)`
  ${up("lg")} {
    margin-top: -142px;
  }
  padding: 0px 24px;
  justify-content: center;
`;
const TitleDesktop = styled(Hero)<{
  color: string;
}>`
  ${down("md")} {
    display: none;
  }
  text-align: center;
  margin-bottom: 40px;
  color: ${(props) => props.color || color.charcoal10};
`;
const TitleMobile = styled(Headline)<{
  color: string;
}>`
  ${up("lg")} {
    display: none;
  }
  margin-top: 24px;
  text-align: center;
  margin-bottom: 40px;
  color: ${(props) => props.color || color.charcoal10};
`;
const ProductContainer = styled(Row)`
  ${up("md")} {
    grid-template-columns: repeat(auto-fit, minmax(199px, max-content));
  }
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(117px, max-content));

  justify-content: center;
  padding: initial;
`;
const ProductCategoryContainer = styled.div<{
  bgColor: string;
  borderColor: string;
}>`
  ${down("md")} {
    background: none;
    padding: 0px;
    border: initial;
    box-shadow: initial;
    border-radius: initial;
  }
  max-width: var(--content-max-width);
  margin: auto;
  padding: 40px 58px 40px 58px;
  background: ${(props) => props.bgColor || "white"};
  border: 1px solid ${(props) => props.borderColor || color.charcoal03};
  box-shadow: 0px 4px 18px 5px rgba(43, 43, 43, 0.05);
  border-radius: 16px;
`;

export type ProductCategoryProps = {
  isLoading: boolean;
  productCategories: ProductCategoryType[];
  channelPath: string;
  content: any;
};

type ProductLinkMapperProps = {
  path: string;
  productCategory: ProductCategoryType;
};
const ProductLinkMapper = ({
  path,
  productCategory,
}: ProductLinkMapperProps) => {
  const { code, displayName, icon, labels } = productCategory;
  const router = useRouter();

  return (
    <Link
      href={{
        pathname: path,
        query:
          productCategory.type === ProductTypes.static ||
          productCategory.type === ProductTypes.dynamic
            ? filterParams(router.query, GLOBAL_PARAMS)
            : {},
      }}
      passHref
    >
      <StyledLink data-gtm-input-code={`product-category-page-${code}`}>
        <ProductCard
          isLoading={false}
          key={code}
          displayName={displayName}
          icon={icon}
          labels={labels}
          data-cy={`landing-page.product-card.${code}`}
        />
      </StyledLink>
    </Link>
  );
};

const ProductLoading = () => (
  <>
    <ProductCard isLoading />
    <ProductCard isLoading />
    <ProductCard isLoading />
  </>
);

const ProductCategory = ({
  isLoading,
  productCategories,
  channelPath,
  content,
}: ProductCategoryProps): ReactElement => {
  const { locale } = useRouter();

  return (
    <Container>
      <Col span={24}>
        <ProductCategoryContainer
          bgColor={content?.categoryListSection?.bgColor}
          borderColor={content?.categoryListSection?.borderColor}
        >
          <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }}>
            <TitleDesktop
              level={4}
              color={content?.categoryListSection?.textColor}
            >
              {content.categoryListSectionTitle[locale]}
            </TitleDesktop>
            <TitleMobile
              level={2}
              color={content?.categoryListSection?.textColor}
            >
              {content.categoryListSectionTitle[locale]}
            </TitleMobile>
          </Col>
          <Col>
            <ProductContainer>
              {isLoading ? (
                <ProductLoading />
              ) : (
                productCategories.map((productCategory) => {
                  switch (productCategory.type) {
                    case ProductTypes.static:
                      return (
                        <ProductLinkMapper
                          productCategory={productCategory}
                          path={formatPathByLogicalGroupChannel(
                            `/${channelPath}/${productCategory.code}/plan`
                          )}
                        />
                      );
                    case ProductTypes.dynamic:
                      return (
                        <ProductLinkMapper
                          productCategory={productCategory}
                          path={formatPathByLogicalGroupChannel(
                            `/${channelPath}/${productCategory.code}`
                          )}
                        />
                      );

                    case ProductTypes.external:
                      return (
                        <ProductLinkMapper
                          productCategory={productCategory}
                          path={embedLocaleLanguageAndEnvToExternalLink(
                            productCategory.externalLink,
                            locale,
                            process.env.NEXT_PUBLIC_BUILD_ENV_NAME
                          )}
                        />
                      );

                    default:
                      return (
                        <ProductCard
                          isLoading={false}
                          key={productCategory.code}
                          displayName={productCategory.displayName}
                          icon={productCategory.icon}
                          labels={productCategory.labels}
                        />
                      );
                  }
                })
              )}
            </ProductContainer>
          </Col>
        </ProductCategoryContainer>
      </Col>
    </Container>
  );
};
export default ProductCategory;
