/* eslint-disable import/prefer-default-export */

import { BUSINESS_ERROR_CODE, ERROR_CODE } from "src/constants/error";

/**
 * check is unknown error.
 * @param error error object any type.
 * @returns true if error is unknown error and false when it's known error.
 */
export const checkUnknownError = (error: any): boolean =>
  error?.code === ERROR_CODE.TECHNICAL_ERROR ||
  !error?.code ||
  typeof error.code !== "string" ||
  !Object.keys(BUSINESS_ERROR_CODE).find(
    (errorKey) => BUSINESS_ERROR_CODE[errorKey] === error.code
  );
