/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from "antd";
import { Form as SundayForm } from "sunday-component/node_modules/antd";
import { Trans, useTranslation } from "react-i18next";
import { Body, Hero, FormItem, Title } from "sunday-component";
import styled from "styled-components";
import { color } from "src/styles/globals";
import { useRef, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import type { DataNode } from "antd/es/tree";
import {
  DROP_LEAD_FORM_FIELDS,
  DROP_LEAD_FORM_VALUES,
} from "src/constants/dropLeadForm";
import {
  EMAIL_PATTERN,
  EN_NUM_SYMBOL,
  ONLY_TH_EN_PATTERN,
} from "src/utils/regEx";
import { useRouter } from "next/router";
import Image from "next/image";
import { checkUnknownError } from "src/utils/errors";
import { logError } from "src/utils/logger";
import { sendLeadOtp, submitLead } from "src/api/lead";
import { axiosErrorUnwrap } from "src/utils/axiosError";
import { BUSINESS_ERROR_CODE } from "src/constants/error";
import { ChannelConfigType } from "src/types/channelConfig";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { down } from "styled-breakpoints";
import { sendOtp, validateOtp } from "src/api/otp";
import { MOBILE_NO_PATTERN } from "src/constants/region";
import { OTP_METHOD } from "src/constants/otp";
import TextBoxForm, {
  TextBoxErrorText,
  TextBoxLabelText,
} from "../Common/Form/TextBoxForm";
import { OtpModal } from "../appForm/Otp/OtpModal";
import CheckBoxWithFrame from "../Common/CheckBoxWithFrame";
import Form from "../Common/Form/Form";
import AutoCompleteForm from "../Common/Form/AutoCompleteForm";
import TextAreaForm from "../Common/Form/TextAreaForm";
import CheckBoxForm from "../Common/Form/CheckBoxForm";
import Button from "../Common/Button";
import ErrorMessageWithIcon from "../Common/Form/ErrorMessageWithIcon";
import ErrorDialog from "../Common/ErrorDialog";
import TreeForm, { generateTreeNode } from "../Common/Form/TreeForm";
import DropLeadThankyou from "./DropLeadThankyou";
import Link from "../Common/Link";

const StyledTitle = styled(Hero)`
  text-align: center;
  ${down("sm")} {
    font-size: 28px;
  }
`;
const StyledBody = styled(Body)`
  text-align: center;
  margin: 16px 16px 24px 16px;
  color: ${color.charcoal09};
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const FormContainer = styled(Row)`
  display: block;
  background: ${color.coolGreyCG2};
  border-radius: 16px;
  padding: 16px 12px;
  margin: 16px;
`;

const CheckBoxWithFrameFormItem = styled(FormItem)`
  margin: 4px 0;
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  color: ${color.successGreeen03};
  font-weight: bold;
  font-size: 17px;
  line-height: 18px;
`;

const Verified = styled(Title).attrs({ level: 9 })`
  margin-left: 10px;
  color: ${color.successGreeen03};
`;

type DropLeadProps = {
  sRefCode: string;
  isFromCarnival: boolean;
  channelConfig: ChannelConfigType;
};

const DropLead = ({
  sRefCode,
  isFromCarnival,
  channelConfig,
}: DropLeadProps) => {
  const { t } = useTranslation(["drop-lead"]);
  const [form] = SundayForm.useForm();
  const router = useRouter();
  const { locale } = router;
  const [isVerifiedOtp, setIsVerifiedOtp] = useState<boolean>(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);
  const [isShowDuplicateMobileNoDialog, setIsShowDuplicateMobileNoDialog] =
    useState<boolean>(false);
  const [isShowTechnicalErrorDialog, setIsShowTechnicalErrorDialog] =
    useState<boolean>(false);
  const [otpDisable, setOtpDisable] = useState<boolean>(true);
  const [otpRef, setOtpRef] = useState<string>("");
  const [otpAttempt, setOtpAttempt] = useState<number>(0);
  const [otpDelay, setOtpDelay] = useState<number>(30);
  const [isOtpLoading, setIsOtpLoading] = useState<boolean>(false);
  const containerRef = useRef(null);
  const { lg } = useBreakpoint();
  const isFromTTT = sRefCode === "23_carnival_ttt_01";

  // refactor otp to common component later
  const maxShortDelayAttempt = 5;
  const shortDelay = 30;
  const longDelay = 300;

  const onSubmit = async (result: any) => {
    const interestedProducts = result[
      DROP_LEAD_FORM_FIELDS.INTERESTED_PRODUCTS
    ].map((interestedProduct: string) => ({
      code: interestedProduct,
      displayName: t(`form.value.interestedProducts.${interestedProduct}`),
    }));
    const payload = {
      fullName: result[DROP_LEAD_FORM_FIELDS.FULL_NAME],
      mobileNo: result[DROP_LEAD_FORM_FIELDS.MOBILE_NO],
      availableCallDay: {
        weekDay: result[DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_WEEKDAY] || false,
        weekEnd: result[DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_WEEKEND] || false,
      },
      availableCallTime: result[DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_TIME],
      interestedProducts,
      detail: result[DROP_LEAD_FORM_FIELDS.DETAIL],
      email: result[DROP_LEAD_FORM_FIELDS.EMAIL],
      sRefCode: result[DROP_LEAD_FORM_FIELDS.S_REF_CODE],
      isAcceptAllTC: result[DROP_LEAD_FORM_FIELDS.IS_ACCEPT_ALL_TC],
      lang: locale,
      logicalChannel: channelConfig.logicalGroupChannelCode,
      businessEntityChannel: channelConfig.businessEntityCode,
      systemChannel: channelConfig.code,
    };
    try {
      await submitLead(payload);
      containerRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
      setIsSubmitSuccess(true);
      setIsVerifiedOtp(false);
    } catch (e) {
      logError("Error occur when submit lead", e);
      setIsShowTechnicalErrorDialog(true);
    }
  };

  const onClickThankyou = () => {
    form.resetFields();
    setIsSubmitSuccess(false);
  };

  const interestedProductOptions: DataNode[] = [
    generateTreeNode({
      title: t("form.value.interestedProducts.HEALTH"),
      key: DROP_LEAD_FORM_VALUES.INTERESTED_PRODUCT.HEALTH,
      children: [
        generateTreeNode({
          title: t("form.value.interestedProducts.HEALTH_ADULT"),
          key: DROP_LEAD_FORM_VALUES.INTERESTED_PRODUCT.HEALTH_ADULT,
        }),
        generateTreeNode({
          title: t("form.value.interestedProducts.HEALTH_KID"),
          key: DROP_LEAD_FORM_VALUES.INTERESTED_PRODUCT.HEALTH_KID,
        }),
        generateTreeNode({
          title: t("form.value.interestedProducts.HEALTH_PA"),
          key: DROP_LEAD_FORM_VALUES.INTERESTED_PRODUCT.HEALTH_PA,
        }),
        generateTreeNode({
          title: t("form.value.interestedProducts.CI"),
          key: DROP_LEAD_FORM_VALUES.INTERESTED_PRODUCT.CI,
        }),
      ],
    }),
    generateTreeNode({
      title: t("form.value.interestedProducts.MOTOR"),
      key: DROP_LEAD_FORM_VALUES.INTERESTED_PRODUCT.MOTOR,
    }),
    generateTreeNode({
      title: t("form.value.interestedProducts.GADGET"),
      key: DROP_LEAD_FORM_VALUES.INTERESTED_PRODUCT.GADGET,
    }),
    generateTreeNode({
      title: t("form.value.interestedProducts.HOME"),
      key: DROP_LEAD_FORM_VALUES.INTERESTED_PRODUCT.HOME,
    }),
  ];

  const availableCallTimeOptions = [
    {
      label: t("form.value.availableCallTime.anytime"),
      value: DROP_LEAD_FORM_VALUES.AVAILABLE_CALL_TIME.ANYTIME,
    },
    {
      label: t("form.value.availableCallTime.morning"),
      value: DROP_LEAD_FORM_VALUES.AVAILABLE_CALL_TIME.MORNING,
    },
    {
      label: t("form.value.availableCallTime.noon"),
      value: DROP_LEAD_FORM_VALUES.AVAILABLE_CALL_TIME.NOON,
    },
    {
      label: t("form.value.availableCallTime.afternoon"),
      value: DROP_LEAD_FORM_VALUES.AVAILABLE_CALL_TIME.AFTERNOON,
    },
    {
      label: t("form.value.availableCallTime.evening"),
      value: DROP_LEAD_FORM_VALUES.AVAILABLE_CALL_TIME.EVENING,
    },
  ];

  const onRequestOtp = async (isResend: boolean) => {
    if (!otpRef || isResend) {
      try {
        const mobileNo = form.getFieldValue(DROP_LEAD_FORM_FIELDS.MOBILE_NO);

        // if user is from carnival, we will use carnival send otp endpoint to check if their mobileNo is duplicated.
        // more detail in https://project9.atlassian.net/browse/RET-2115
        const res = isFromCarnival
          ? await sendLeadOtp(mobileNo, sRefCode, locale)
          : await sendOtp(mobileNo, locale, OTP_METHOD.SMS);
        const { referenceNumber: newOtp } = res;
        setOtpRef(newOtp);
        setOtpAttempt(otpAttempt + 1);
        setOtpDelay(otpAttempt < maxShortDelayAttempt ? shortDelay : longDelay);
      } catch (error) {
        const errorUnwrap = axiosErrorUnwrap(error);
        if (
          errorUnwrap.code === BUSINESS_ERROR_CODE.NOT_MATCHED_WITH_CONDITION &&
          isFromCarnival
        ) {
          setIsVerifiedOtp(false);
          setIsShowDuplicateMobileNoDialog(true);
        }
        const isUnknownError = checkUnknownError(error);
        if (isUnknownError) {
          logError("[drop-lead-section] send otp fail", { locale }, error);
        }
        setOtpDisable(true);
        form.setFields([
          {
            name: "otp",
            errors: [
              (
                <TextBoxErrorText>
                  {t(`form.validate.otp.notSuccess`)}
                </TextBoxErrorText>
              ) as unknown as string,
            ],
          },
        ]);
      }
    }
  };

  const onSubmitOtp = async () => {
    setIsOtpLoading(true);
    const otpNo = form.getFieldValue("otp");
    try {
      const res = await validateOtp(otpNo, otpRef);
      const valid = res?.isValid;
      if (valid) {
        setIsVerifiedOtp(true);
        form.setFields([
          {
            name: DROP_LEAD_FORM_FIELDS.MOBILE_NO,
            errors: [],
          },
        ]);
      } else if (res?.isOtpExpired) {
        form.setFields([
          {
            name: "otp",
            errors: [t(`form.validate.otp.expired`)],
          },
        ]);
        logError("[drop-lead-section] submit otp fail because otp expired", {
          locale,
          referenceNo: otpRef,
        });
      } else {
        form.setFields([
          {
            name: "otp",
            errors: [t(`form.validate.otp.invalid`)],
          },
        ]);
        logError("[drop-lead-section] submit otp fail", {
          locale,
          referenceNo: otpRef,
        });
      }
      setIsOtpLoading(false);
    } catch (error) {
      const isUnknownError = checkUnknownError(error);
      if (isUnknownError) {
        logError(
          "[insured-person-form-component] submit otp fail",
          { locale, referenceNo: otpRef },
          error
        );
      }
      form.setFields([
        {
          name: "otp",
          errors: [
            (
              <TextBoxErrorText>
                {t(`form.validate.otp.invalid`)}
              </TextBoxErrorText>
            ) as unknown as string,
          ],
        },
      ]);
      setIsOtpLoading(false);
    }
  };

  const validateMobileNumber = (_, value, submitError) => {
    if (!MOBILE_NO_PATTERN.test(value)) {
      setOtpDisable(true);
      return submitError(
        <TextBoxErrorText>
          {t("form:validation.pleaseEnterValid", {
            field: t("form.label.mobileNo")?.toLocaleLowerCase(),
          })}
        </TextBoxErrorText>
      );
    }
    if (!isVerifiedOtp) {
      setOtpDisable(false);
      setOtpRef("");
      return submitError(
        <TextBoxErrorText>{t(`form.validate.otp.verify`)}</TextBoxErrorText>
      );
    }

    return submitError();
  };

  const validateAvailableCall = (_) => {
    const weekDayFormValue = form.getFieldValue(
      DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_WEEKDAY
    );
    const weekEndFormValue = form.getFieldValue(
      DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_WEEKEND
    );
    if (weekDayFormValue !== true && weekEndFormValue !== true) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        <ErrorMessageWithIcon
          errorMessage={t("form.validate.availableCall.empty")}
        />
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Row id="drop-lead" justify="center">
        <Col ref={containerRef} sm={24} lg={12}>
          <StyledTitle level={4}>{t("title")}</StyledTitle>
          <StyledBody level={lg ? 5 : 7}>{t("subTitle")}</StyledBody>
          <FormContainer>
            {!isSubmitSuccess && (
              <StyledForm
                form={form}
                onFieldsChange={(values) => {
                  const availableCallFormValue = values.find(
                    (value) =>
                      value.name[0] ===
                        DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_WEEKDAY ||
                      value.name[0] ===
                        DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_WEEKEND
                  );
                  if (availableCallFormValue) {
                    form.validateFields([
                      DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_DAY,
                    ]);
                  }
                }}
                scrollToFirstError
                onFinish={onSubmit}
              >
                <Row gutter={[12, 0]}>
                  <Col xs={24} md={12}>
                    {/* First name - Last name */}
                    <Col span={24}>
                      <FormItem
                        name={DROP_LEAD_FORM_FIELDS.FULL_NAME}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: (
                              <TextBoxErrorText>
                                {t("form:validation.mustHave")}
                              </TextBoxErrorText>
                            ),
                          },
                          {
                            pattern: ONLY_TH_EN_PATTERN,
                            message: (
                              <TextBoxErrorText>
                                {t("form:validation.onlyThEn")}
                              </TextBoxErrorText>
                            ),
                          },
                        ]}
                      >
                        <TextBoxForm
                          label={t("form.label.fullName")}
                          placeholder={t("form.placeholder.fullName")}
                        />
                      </FormItem>
                    </Col>
                    {/* Mobile number */}
                    <Col span={24}>
                      <FormItem
                        name={DROP_LEAD_FORM_FIELDS.MOBILE_NO}
                        extra={t(`form.label.mobileNoInfo`)}
                        shouldUpdate
                        validateFirst
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: (
                              <TextBoxErrorText>
                                {t("form:validation.mustHave")}
                              </TextBoxErrorText>
                            ),
                          },
                          {
                            validator: validateMobileNumber,
                          },
                        ]}
                      >
                        <TextBoxForm
                          type="tel"
                          label={t("form.label.mobileNo")}
                          placeholder={t("form.placeholder.mobileNo")}
                          onChange={() => setIsVerifiedOtp(false)}
                          suffix={
                            !isVerifiedOtp && !isShowDuplicateMobileNoDialog ? (
                              <OtpModal
                                otpField="otp"
                                disable={otpDisable}
                                otpRef={otpRef}
                                delay={otpDelay}
                                onClickSend={onRequestOtp}
                                onClickSubmit={onSubmitOtp}
                                otpVerified={isVerifiedOtp}
                                target={form.getFieldValue(
                                  DROP_LEAD_FORM_FIELDS.MOBILE_NO
                                )}
                                method={OTP_METHOD.SMS}
                                form={form}
                                isLoading={isOtpLoading}
                              />
                            ) : (
                              <>
                                <StyledCheckOutlined />
                                <Verified>
                                  {t("form.label.mobileNoVerified")}
                                </Verified>
                              </>
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                    {/* When will you be available for a call? */}
                    <Col span={24}>
                      {/* this FormItem use to validate if user select one of weekday or weekend */}
                      <FormItem
                        name={DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_DAY}
                        rules={[
                          {
                            validator: validateAvailableCall,
                          },
                        ]}
                      >
                        <TextBoxLabelText>
                          {t("form.label.availableCall")}
                        </TextBoxLabelText>
                        <Row gutter={[16, 0]}>
                          <Col span={12}>
                            <CheckBoxWithFrameFormItem
                              name={
                                DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_WEEKDAY
                              }
                              valuePropName="checked"
                              // eslint-disable-next-line react/jsx-boolean-value
                              initialValue={true}
                            >
                              <CheckBoxWithFrame
                                label={t("form.value.availableCallDay.weekDay")}
                                frameBackground="white"
                              />
                            </CheckBoxWithFrameFormItem>
                          </Col>
                          <Col span={12}>
                            <CheckBoxWithFrameFormItem
                              name={
                                DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_WEEKEND
                              }
                              valuePropName="checked"
                            >
                              <CheckBoxWithFrame
                                label={t("form.value.availableCallDay.weekEnd")}
                                frameBackground="white"
                              />
                            </CheckBoxWithFrameFormItem>
                          </Col>
                        </Row>
                        <FormItem
                          name={DROP_LEAD_FORM_FIELDS.AVAILABLE_CALL_TIME}
                          initialValue={
                            DROP_LEAD_FORM_VALUES.AVAILABLE_CALL_TIME.ANYTIME
                          }
                          noStyle
                        >
                          <AutoCompleteForm
                            options={availableCallTimeOptions}
                          />
                        </FormItem>
                      </FormItem>
                    </Col>
                  </Col>
                  <Col xs={24} md={12}>
                    {/* What products are you interested? */}
                    <Col span={24}>
                      <FormItem
                        name={DROP_LEAD_FORM_FIELDS.INTERESTED_PRODUCTS}
                        valuePropName="checkedKeys"
                        trigger="onCheck"
                        validateTrigger={["onCheck"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <ErrorMessageWithIcon
                                errorMessage={t("form:validation.atLeastOne")}
                              />
                            ),
                          },
                        ]}
                      >
                        <TreeForm
                          label={t("form.label.interestedProducts")}
                          treeData={interestedProductOptions}
                        />
                      </FormItem>
                    </Col>
                  </Col>
                </Row>
                {/* More details: (Optional) */}
                <Col span={24}>
                  <Col>
                    <FormItem
                      name={DROP_LEAD_FORM_FIELDS.DETAIL}
                      initialValue={
                        isFromTTT
                          ? DROP_LEAD_FORM_VALUES.DETAIL_PA[locale]
                          : DROP_LEAD_FORM_VALUES.DETAIL_DEFAULT[locale]
                      }
                      rules={[
                        {
                          max: 1000,
                          message: (
                            <TextBoxErrorText>
                              {t("form:validation.characterLengthMax", {
                                max: 1000,
                              })}
                            </TextBoxErrorText>
                          ),
                        },
                      ]}
                    >
                      <TextAreaForm
                        rows={isFromTTT ? 10 : 7}
                        autoSize={false}
                        label={t("form.label.detail")}
                        optionalText={t("form:optionalSuffix")}
                      />
                    </FormItem>
                  </Col>
                </Col>
                <Row gutter={[24, 0]}>
                  {/* Email address (optional) */}
                  <Col xs={24} md={12}>
                    <FormItem
                      name={DROP_LEAD_FORM_FIELDS.EMAIL}
                      rules={[
                        {
                          pattern: EMAIL_PATTERN,
                          message: (
                            <TextBoxErrorText>
                              {t("form:validation.pleaseEnterValid", {
                                field:
                                  t("form.label.email")?.toLocaleLowerCase(),
                              })}
                            </TextBoxErrorText>
                          ),
                        },
                      ]}
                    >
                      <TextBoxForm
                        label={t("form.label.email")}
                        placeholder={t("form.placeholder.email")}
                        optionalText={t("form:optionalSuffix")}
                      />
                    </FormItem>
                  </Col>
                  {/* Referral code (optional) */}
                  <Col xs={24} md={12}>
                    <FormItem
                      initialValue={sRefCode}
                      name={DROP_LEAD_FORM_FIELDS.S_REF_CODE}
                      rules={[
                        {
                          pattern: EN_NUM_SYMBOL,
                          message: (
                            <TextBoxErrorText>
                              {t("form:validation.onlyEnNumSymbol")}
                            </TextBoxErrorText>
                          ),
                        },
                        {
                          max: 100,
                          message: (
                            <TextBoxErrorText>
                              {t("form:validation.characterLengthMax", {
                                max: 100,
                              })}
                            </TextBoxErrorText>
                          ),
                        },
                      ]}
                    >
                      <TextBoxForm
                        disabled={sRefCode != null}
                        label={t("form.label.sRefCode")}
                        placeholder={t("form.placeholder.sRefCode")}
                        optionalText={t("form:optionalSuffix")}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Col>
                  <FormItem
                    name={DROP_LEAD_FORM_FIELDS.IS_ACCEPT_ALL_TC}
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        transform: (value) => value || undefined,
                        type: "boolean",
                        message: (
                          <ErrorMessageWithIcon
                            errorMessage={t("form:validation.pleaseAgree")}
                          />
                        ),
                      },
                    ]}
                  >
                    <CheckBoxForm
                      label={
                        <Body level={7}>
                          <Trans
                            t={t}
                            i18nKey="tc"
                            components={[
                              <Link
                                href={`https://easysunday.com/${locale}/terms-and-conditions`}
                              >
                                child
                              </Link>,
                              <Link
                                href={`https://easysunday.com/${locale}/privacy`}
                              >
                                child
                              </Link>,
                              <Link
                                href={
                                  locale === "th"
                                    ? "https://static.easysunday.com/retail/acceptance/sunday/marketing_privacy_notice_sunday_TH_20230404.pdf"
                                    : "https://static.easysunday.com/retail/acceptance/sunday/marketing_privacy_notice_sunday_EN_20230404.pdf"
                                }
                              >
                                child
                              </Link>,
                            ]}
                          />
                        </Body>
                      }
                    />
                  </FormItem>
                </Col>
                <Row justify="center">
                  <Col xs={24} md={12}>
                    <Button block htmlType="submit">
                      {t("form.submit")}
                    </Button>
                  </Col>
                </Row>
              </StyledForm>
            )}
            {isSubmitSuccess && (
              <DropLeadThankyou
                isFromCarnival={isFromCarnival}
                onClickThankyou={onClickThankyou}
                t={t}
              />
            )}
          </FormContainer>
        </Col>
      </Row>
      <ErrorDialog
        isVisible={isShowDuplicateMobileNoDialog}
        title={t("duplicateMobileNo.hero")}
        description={t("duplicateMobileNo.body")}
        icon={<Image width="56px" height="56px" src="/static/alert.svg" />}
        errorButtons={[
          {
            text: t("duplicateMobileNo.ok"),
            onClick: () => setIsShowDuplicateMobileNoDialog(false),
          },
        ]}
      />
      <ErrorDialog
        isVisible={isShowTechnicalErrorDialog}
        title={t("technical-error-modal:hero")}
        description={t("technical-error-modal:body")}
        errorButtons={[
          {
            text: t("technical-error-modal:ok"),
            onClick: () => setIsShowTechnicalErrorDialog(false),
          },
        ]}
      />
    </>
  );
};

export default DropLead;
