import { down, up } from "styled-breakpoints";
import styled from "styled-components";

export const DesktopContainer = styled("div")`
  ${down("lg")} {
    display: none;
  }
`;
export const MobileContainer = styled("div")`
  ${up("xl")} {
    display: none;
  }
  ${down("md")} {
    white-space: initial;
  }
`;
