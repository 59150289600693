import { Col, Row } from "antd";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { Hero as H, Headline as HL, Body as Bd } from "sunday-component";
import { useRouter } from "next/router";
import {
  DesktopContainer as DTC,
  MobileContainer as MC,
} from "../Common/Container";

const CustomImage = styled("img")<{
  cursor?: string;
  mobileWidth?: string;
  mobileHeight?: string;
  height?: string;
}>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  cursor: ${(props) => props.cursor || "default"};
  ${down("sm")} {
    width: ${(props) => props.mobileWidth || "100%"};
    height: ${(props) => props.mobileHeight || "auto"};
  }
`;
const DesktopContainer = styled(DTC)`
  padding-inline-end: 1px;
`;
const MobileContainer = styled(MC)`
  padding-inline-end: 1px;
`;

export type USPBannerComponentProps = {
  image: string;
  content: any;
};
const USPBannerComponent = ({ image, content }: USPBannerComponentProps) => {
  const { locale } = useRouter();
  const Body = styled(Bd)`
    text-align: start;
    line-height: 27.5px;
    color: ${content.keyUsp01.textColor};
    margin-top: 24px;
  `;
  const Headline = styled(HL)`
    color: ${content.keyUsp01.textColor};
    text-align: start;
    line-height: 39.2px;
  `;
  const Hero = styled(H)`
    color: ${content.keyUsp01.textColor};
    text-align: start;
    line-height: 47.6px;
  `;
  const styles = {
    col: {
      sundayTopUpPlanBox: { background: content.keyUsp01.bgcolor },
      sundayTopUpPlanContainer: { maxWidth: "552px", margin: "40px 16px" },
    },
    row: {
      fullHeight: { height: "100%" },
    },
    button: {
      marginBottom: "16px",
    },
  };
  return content.keyUsp01.enabled ? (
    <Row>
      <Col xs={24} lg={12}>
        <CustomImage height="100%" src={content.keyUsp01.imageUrl} />
      </Col>
      <Col xs={24} lg={12} style={styles.col.sundayTopUpPlanBox}>
        <Row justify="center" align="middle" style={styles.row.fullHeight}>
          <Col style={styles.col.sundayTopUpPlanContainer}>
            <DesktopContainer>
              <Hero level={4}>{content.keyUsp01.title[locale]}</Hero>
              <Body level={5}>{content.keyUsp01.body[locale]}</Body>
            </DesktopContainer>
            <MobileContainer>
              <Headline level={2}>{content.keyUsp01.title[locale]}</Headline>
              <Body level={5}>{content.keyUsp01.body[locale]}</Body>
            </MobileContainer>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default USPBannerComponent;
