import styled from "styled-components";
import { Body } from "sunday-component";

const LinkBody = styled(Body)`
  display: inline;
  color: var(--ui-form-button-text-enabled-text-color);
  cursor: pointer;

  &:hover {
    color: var(--ui-form-button-text-hover-text-color);
    text-decoration: var(--ui-form-button-text-hover-text-decoration);
    text-decoration-color: var(--ui-form-button-text-hover-text-color);
    font-weight: var(--ui-form-button-text-hover-text-decoration);
  }
  &:focus {
    color: var(--ui-form-button-text-focused-text-color);
    text-decoration: var(--ui-form-button-text-focused-text-decoration);
    text-decoration-color: var(--ui-form-button-text-focused-text-color);
    font-weight: var(--ui-form-button-text-hover-text-decoration);
  }
  &:disabled {
    color: var(--ui-form-button-text-disabled-text-color);
  }
`;

export default LinkBody;
