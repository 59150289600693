import { CheckboxChangeEvent } from "antd/lib/checkbox";
import styled from "styled-components";
import { color } from "../../styles/globals";
import { CustomStyledCheckBox } from "./CheckBox";

const SundayCheckBox = styled(CustomStyledCheckBox)<{
  frameBackground?: string;
}>`
  align-items: flex-start;
  display: inline-flex !important;
  background-color: ${(props) => props.frameBackground || "initial"};
  .sunday-component-checkbox {
    top: 0em;
    margin: 2px;
  }
  .sunday-component-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }
  .sunday-component-checkbox + span {
    padding: 0px;
    margin-left: 10px;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  width: 100%;
  border: 1px solid ${color.charcoal03};
  border-radius: 8px;
  padding: 16px 16px 17px 18px;
`;

export type CheckBoxProp = {
  label?: string | JSX.Element;
  disabled?: boolean;
  defaultChecked?: boolean;
  frameBackground?: string;
  dataTestId?: string;
  onChange?: (event: CheckboxChangeEvent) => void;
};

const CheckBoxWithFrame = ({
  label,
  disabled = false,
  defaultChecked = false,
  dataTestId,
  frameBackground,
  onChange = (event: CheckboxChangeEvent) => event,
  ...prop
}: CheckBoxProp) => {
  return (
    <SundayCheckBox
      data-testid={dataTestId}
      data-gtm-input-code={dataTestId}
      label={label}
      onChange={onChange}
      disabled={disabled}
      defaultChecked={defaultChecked}
      frameBackground={frameBackground}
      {...prop}
    />
  );
};

export default CheckBoxWithFrame;
