/* eslint-disable import/prefer-default-export */
export const DROP_LEAD_FORM_FIELDS = {
  FULL_NAME: "fullName",
  MOBILE_NO: "mobileNo",
  AVAILABLE_CALL_DAY: "availableCallDay",
  AVAILABLE_CALL_WEEKDAY: "availableCallWeekDay",
  AVAILABLE_CALL_WEEKEND: "availableCallWeekEnd",
  AVAILABLE_CALL_TIME: "availableCallTime",
  INTERESTED_PRODUCTS: "interestedProducts",
  DETAIL: "detail",
  EMAIL: "email",
  S_REF_CODE: "sRefCode",
  IS_ACCEPT_ALL_TC: "isAcceptAllTc",
};

export const DROP_LEAD_FORM_VALUES = {
  AVAILABLE_CALL_TIME: {
    ANYTIME: "Anytime",
    MORNING: "08:00 - 12:00",
    NOON: "12:00 - 13:00",
    AFTERNOON: "13:00 - 17:00",
    EVENING: "17:00 - 20:00",
  },
  DETAIL_PA: {
    en: "> Health: (Age....  Date of birth.........)\n> Motor:  (Brand....  Model....  Year.... )\n> Gadget: (Brand....  Model....  Year.... )\n> Home: (Type....  Floor count....)\n\nFor Free PA Songkarn\n> id card number:\n> date of birth (DD/MM/YYYY):\n\nOr, any special request:",
    th: "> สุขภาพ: (อายุ...  วัน เดือน ปีเกิด.........)\n> รถยนต์:  (ยี่ห้อ....  รุ่น....  ปีที่ผลิต.... )\n> อุปกรณ์อิเล็กทรอนิกส์: (ยี่ห้อ....  รุ่น....  ปีที่ผลิต.... )\n> บ้าน: (ประเภท....  จำนวนชั้น....)\n\nสำหรับโครงการ Free PA สงกรานต์\n> เลขบัตรประชาชน 13 หลัก:\n> วันเดือนปีเกิด (DD/MM/YYYY):\n\nหรือระบุความต้องการอื่นๆ เพิ่มเติม",
  },
  DETAIL_DEFAULT: {
    en: "> Health: (Age....  Date of birth.........)\n> Motor:  (Brand....  Model....  Year.... )\n> Gadget: (Brand....  Model....  Year.... )\n> Home: (Type....  Floor count....)\n\nOr, any special request:",
    th: "> สุขภาพ: (อายุ...  วัน เดือน ปีเกิด.........)\n> รถยนต์:  (ยี่ห้อ....  รุ่น....  ปีที่ผลิต.... )\n> อุปกรณ์อิเล็กทรอนิกส์: (ยี่ห้อ....  รุ่น....  ปีที่ผลิต.... )\n> บ้าน: (ประเภท....  จำนวนชั้น....)\n\nหรือระบุความต้องการอื่นๆ เพิ่มเติม",
  },
  INTERESTED_PRODUCT: {
    HEALTH: "HEALTH",
    HEALTH_ADULT: "HEALTH_ADULT",
    HEALTH_KID: "HEALTH_KID",
    HEALTH_PA: "HEALTH_PA",
    CI: "CI",
    MOTOR: "MOTOR",
    GADGET: "GADGET",
    HOME: "HOME",
  },
};

export const DROP_LEAD_ANCHOR = "drop-lead";
