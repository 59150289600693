import { Modal } from "antd";
import { useState } from "react";
import styled from "styled-components";

const PopupCustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

const Image = styled("img")``;

const ButtonImage = styled("button")`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
`;

type PopupModalProps = {
  imageUrl: string;
};

const PopupModal = ({ imageUrl }: PopupModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
  return (
    <PopupCustomModal
      closable
      centered
      visible={isModalVisible}
      footer={null}
      onCancel={() => setIsModalVisible(false)}
    >
      <ButtonImage onClick={() => setIsModalVisible(false)}>
        <Image alt="popup" width="100%" src={imageUrl} />
      </ButtonImage>
    </PopupCustomModal>
  );
};

export default PopupModal;
