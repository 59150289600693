import { Col, Row } from "antd";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import { Hero as H, Body as Bd, Headline as HL } from "sunday-component";
import { useRouter } from "next/router";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { color } from "../../styles/globals";

const Container = styled(Col)`
  padding: 80px 16px;
`;
const Hero = styled(H).attrs({ level: 4 })`
  color: ${color.charcoal10};
  text-align: center;

  ${down("sm")} {
    display: none;
  }
`;
const Body = styled(Bd)`
  padding-top: 16px;
  white-space: initial;
  color: ${color.charcoal09};
  text-align: center;
  ${down("sm")} {
    white-space: normal;
  }
`;
const Headline = styled(HL).attrs({ level: 2 })`
  ${up("md")} {
    display: none;
  }
  text-align: center;
  color: ${color.charcoal10};
`;
const LogoImg = styled.img`
  ${down("sm")} {
    max-height: 48px;
  }
  max-height: 104px;
  min-height: 80px;
`;
const LogoComponent = styled(Col)`
  align-items: center;
`;

const LogoContainer = styled(Row).attrs({ justify: "center" })`
  ${up("sm")} {
    margin-top: 24px;
    column-gap: 48px;
    row-gap: 16px;
  }
  margin-top: 24px;
  column-gap: 16px;
  row-gap: 16px;
`;

export type PartnerProps = {
  title: {
    en: string;
    id: string;
  };
  subTitle: {
    en: string;
    id: string;
  };
  logos: string[];
  content: any;
};

const Partner = ({ content }: PartnerProps) => {
  const router = useRouter();
  const { lg } = useBreakpoint();
  return content.partners.enabled ? (
    <Container lg={{ span: 16, offset: 4 }}>
      <Row justify="center">
        <Headline>{content.partners.title[router.locale]}</Headline>
        <Hero>{content.partners.title[router.locale]}</Hero>
      </Row>
      <Row justify="center">
        <Body level={lg ? 5 : 7}>{content.partners.body[router.locale]}</Body>
      </Row>
      <Col lg={{ span: 16, offset: 4 }}>
        <LogoContainer align="middle" style={{ rowGap: "16px" }}>
          {content.partners.logoList.map((logo) => (
            <LogoComponent>
              <LogoImg key={logo.code} src={logo.image_url} alt={logo.code} />
            </LogoComponent>
          ))}
        </LogoContainer>
      </Col>
    </Container>
  ) : (
    <></>
  );
};

export default Partner;
