import { Col, Row } from "antd";
import { Property } from "csstype";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import { Headline, Hero } from "sunday-component";
import { useRouter } from "next/router";
import { color } from "../../styles/globals";
import LinkBody from "../Common/LinkBody";

const IconImg = styled.img`
  max-width: 240px;
  height: 128px;
  ${down("md")} {
    height: 70px;
  }
  ${down("sm")} {
    height: 70px;
  }
`;

const Container = styled(Col)`
  ${down("sm")} {
    background: ${color.white};
    margin: 80px 0px;
  }
  background: ${color.white};
  max-width: var(--content-max-width);
  margin: 80px 24px 80px 24px;
`;

const IconListContainer = styled(Row)`
  padding-top: 3em;
`;

const IconContainer = styled.div`
  margin: auto;
  ${down("sm")} {
    margin-bottom: 40px;
  }
`;

const IconWrapper = styled.div`
  height: 80px;
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  @media only screen and (min-width: 992px) {
    height: 150px;
    width: 240px;
  }
`;

const IconTitle = styled.div`
  margin-top: 24px;
`;

const DesktopTitle = styled(Hero).attrs({ level: 4 })`
  color: ${color.charcoal10};
  text-align: center;
  ${down("sm")} {
    display: none;
  }
`;

const MobileTitle = styled(Headline).attrs({ level: 2 })`
  color: ${color.charcoal10};
  text-align: center;
  margin-left: 24px;
  margin-right: 24px;
  ${up("md")} {
    display: none;
  }
`;

const MobileContactTitle = styled(Headline).attrs({
  level: 6,
  className: "ContactUsIconTile",
})`
  color: ${color.charcoal10};
  ${up("md")} {
    display: none;
  }
`;
const DesktopContactTitle = styled(Headline).attrs({
  level: 4,
  className: "ContactUsIconTile",
})`
  color: ${color.charcoal10};
  margin-bottom: 8px;
  ${down("md")} {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${down("sm")} {
    display: none;
  }
`;
const textCenter = {
  textAlign: "center" as Property.TextAlign,
};

const fontStyle = {
  subTitle: {
    fontFamily: "font-with-head-sc",
    textDecoration: "underline",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 25,
  },
};

const ContactUs = ({ content }: any) => {
  const { locale } = useRouter();

  return content.contactSection.enabled ? (
    <Row justify="center">
      <Container span={20} offset={2}>
        <Col xs={{ span: 24 }}>
          <DesktopTitle>
            {content.contactSection.sectionTitle[locale]}
          </DesktopTitle>
          <MobileTitle>
            {content.contactSection.sectionTitle[locale]}
          </MobileTitle>
        </Col>
        <IconListContainer justify="center">
          {content.contacts.cs.enabled ? (
            <Col
              style={textCenter}
              lg={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              xs={{ span: 22, offset: 0 }}
            >
              <IconContainer>
                <a href={content.contacts.cs.telLink}>
                  <IconWrapper>
                    <IconImg
                      className="ContactUsIcon"
                      src="/static/illus-phone.svg"
                      alt="phone"
                    />
                  </IconWrapper>
                  <IconTitle>
                    <DesktopContactTitle>
                      {content.contacts.cs.title[locale]}
                    </DesktopContactTitle>
                    <MobileContactTitle>
                      {content.contacts.cs.title[locale]}
                    </MobileContactTitle>
                    <LinkBody style={fontStyle.subTitle}>
                      {content.contacts.cs.tel}
                    </LinkBody>
                  </IconTitle>
                </a>
              </IconContainer>
            </Col>
          ) : (
            <></>
          )}
          {content.contacts.email.enabled ? (
            <Col
              style={textCenter}
              lg={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              xs={{ span: 22, offset: 0 }}
            >
              <IconContainer>
                <a href={content.contacts.email.emailLink}>
                  <IconWrapper>
                    <IconImg
                      className="ContactUsIcon"
                      src="/static/illus-email.svg"
                      alt="email"
                    />
                  </IconWrapper>
                  <IconTitle>
                    <DesktopContactTitle>
                      {content.contacts.email.title[locale]}
                    </DesktopContactTitle>
                    <MobileContactTitle>
                      {content.contacts.email.title[locale]}
                    </MobileContactTitle>

                    <LinkBody style={fontStyle.subTitle}>
                      {content.contacts.email.email}
                    </LinkBody>
                  </IconTitle>
                </a>
              </IconContainer>
            </Col>
          ) : (
            <></>
          )}
          {content.contacts?.whatsapp?.enabled ? (
            <Col
              style={textCenter}
              lg={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              xs={{ span: 22, offset: 0 }}
            >
              <IconContainer>
                <a href={content.contacts.whatsapp.whatsappLink}>
                  <IconWrapper>
                    <IconImg
                      className="ContactUsIcon"
                      src="/static/illus-whatsapp.svg"
                      alt="whatsapp"
                    />
                  </IconWrapper>
                  <IconTitle>
                    <DesktopContactTitle>
                      {content.contacts.whatsapp.title[locale]}
                    </DesktopContactTitle>
                    <MobileContactTitle>
                      {content.contacts.whatsapp.title[locale]}
                    </MobileContactTitle>
                    <LinkBody style={fontStyle.subTitle}>
                      {" "}
                      {content.contacts.whatsapp.whatsappNumber}
                    </LinkBody>
                  </IconTitle>
                </a>
              </IconContainer>
            </Col>
          ) : (
            <></>
          )}
          {content.contacts.wa.enabled ? (
            <Col
              style={textCenter}
              lg={{ span: 8, offset: 0 }}
              xs={{ span: 22, offset: 0 }}
            >
              <IconContainer>
                <a href={content.contacts.wa.waIdLink}>
                  <IconWrapper>
                    <IconImg
                      className="ContactUsIcon"
                      src="/static/illus-wa.svg"
                      alt="line"
                    />
                  </IconWrapper>
                  <IconTitle>
                    <DesktopContactTitle>
                      {content.contacts.wa.title[locale]}
                    </DesktopContactTitle>
                    <MobileContactTitle>
                      {content.contacts.wa.title[locale]}
                    </MobileContactTitle>
                    <LinkBody style={fontStyle.subTitle}>
                      {" "}
                      {content.contacts.wa.waId}
                    </LinkBody>
                  </IconTitle>
                </a>
              </IconContainer>
            </Col>
          ) : (
            <></>
          )}
        </IconListContainer>
      </Container>
    </Row>
  ) : (
    <></>
  );
};

export default ContactUs;
