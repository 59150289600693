import type { GetServerSideProps, GetServerSidePropsContext } from "next";

import { ReactElement } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { I18Text } from "src/types/i18Text";
import { ParsedUrlQuery } from "querystring";
import PopupModal from "src/components/Common/PopupModal";
import { useRouter } from "next/router";

import getChannelConfigByPathName from "src/api/channel";
import { ChannelConfigType } from "src/types/channelConfig";
import { checkUnknownError } from "src/utils/errors";
import CHANNEL_SIZE from "src/constants/channel";
import { LOGICAL_GROUP_CHANNEL_CODE } from "src/constants/logicalGroupChannelCode";
import {
  filterParams,
  formatPathByLogicalGroupChannel,
  getUrlWithQuery,
} from "src/utils/url";
import DropLead from "src/components/DropLead/DropLead";
import { DROP_LEAD_ANCHOR } from "src/constants/dropLeadForm";
import { GLOBAL_PARAMS } from "src/constants/params";
import USPBanner, {
  USPBannerComponentProps,
} from "../../components/LandingPage/USPBanner";
import Banner, { BannerProps } from "../../components/LandingPage/Banner";
import ProductCategory from "../../components/LandingPage/ProductCategory";
import ContactUs from "../../components/LandingPage/ContactUs";
import USPList, {
  USPListComponentProp,
} from "../../components/LandingPage/USPList";
import Partner, { PartnerProps } from "../../components/LandingPage/Partner";
import { getProductCategoryActiveListViaServerSide } from "../../api/productCategory";

import { useProductCategoryContext } from "../../providers";

export type HomeProps = {
  channelPath: string;
  channelCode: string;
  popup: I18Text;
  USPBannerProps: USPBannerComponentProps;
  USPitemsProps: USPListComponentProp;
  bannerProps: BannerProps;
  partnerProps: PartnerProps;
  channelConfig: ChannelConfigType;
  sRefCode?: string;
  isFromCarnival: boolean;
};

export interface CustomParams extends ParsedUrlQuery {
  channelPath: string;
}

const HomePage = ({
  channelPath,
  popup,
  USPBannerProps,
  USPitemsProps,
  bannerProps,
  partnerProps,
  channelConfig,
  sRefCode,
  isFromCarnival,
}: HomeProps): ReactElement => {
  const { content } = channelConfig;
  const router = useRouter();
  const lang = router.locale || "th";
  const popupUrl = lang === "th" ? popup?.th : popup?.en;
  const isFromDropLead = router.asPath?.split("#")[1] === DROP_LEAD_ANCHOR;
  const { isLoading: isProductCategoryLoading, productCategories } =
    useProductCategoryContext();

  return (
    <div>
      <Banner {...bannerProps} content={content} />
      <ProductCategory
        isLoading={isProductCategoryLoading}
        productCategories={productCategories}
        channelPath={channelPath}
        content={content}
      />
      <ContactUs content={content} />
      <USPBanner image={USPBannerProps.image} content={content} />
      <USPList {...USPitemsProps} content={content} />
      <Partner {...partnerProps} content={content} />
      {content.dropLead.enabled && (
        <DropLead
          channelConfig={channelConfig}
          sRefCode={sRefCode}
          isFromCarnival={isFromCarnival}
        />
      )}
      {popupUrl && !isFromDropLead && <PopupModal imageUrl={popupUrl} />}
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<HomeProps, CustomParams> =
  async (context: GetServerSidePropsContext<CustomParams>) => {
    const channelPath = context.params?.channelPath;
    const sRefCode =
      typeof context.query?.sRefCode === "object"
        ? context.query?.sRefCode[0]
        : (context.query?.sRefCode as string) || null;
    const { query } = context;
    const carnivalPrefixs = JSON.parse(
      process.env.NEXT_PUBLIC_RETAIL_CARNIVAL_S_REF_CODE_PREFIXS
    ) as string[];
    const isFromCarnival = carnivalPrefixs.some((carnivalPrefix) =>
      sRefCode
        ?.toLocaleLowerCase()
        ?.startsWith(carnivalPrefix?.toLocaleLowerCase())
    );

    try {
      const channelConfig = await getChannelConfigByPathName(channelPath);
      if (!channelConfig) {
        return {
          notFound: true,
        };
      }

      if (
        channelConfig.logicalGroupChannelCode !== LOGICAL_GROUP_CHANNEL_CODE
      ) {
        return {
          notFound: true,
        };
      }

      const { content, code: channelCode, size } = channelConfig;

      // redirect to product category when on home page and channel size M
      if (size === CHANNEL_SIZE.M) {
        const { res } = context;

        const productCategoryList =
          await getProductCategoryActiveListViaServerSide(channelCode);

        const validProductCategory = productCategoryList[0];
        if (res) {
          res.writeHead(307, {
            Location: getUrlWithQuery(
              formatPathByLogicalGroupChannel(
                `/${channelPath}/${validProductCategory.code}`
              ),
              filterParams(query, GLOBAL_PARAMS)
            ),
          });
          res.end();
        }
      }

      return {
        props: {
          popup: {
            th: null,
            en: null,
            id: null,
          },
          USPBannerProps: {
            image:
              "https://health.staging.easysunday.com/static/images/non_partner_manual.png",
            content,
          },
          USPitemsProps: {
            items: [
              {
                title: {
                  th: "ซื้อออนไลน์ง่ายๆ",
                  en: "Easy to buy.",
                },
                description: {
                  th: "เราทำให้ประสบการ์การซื้อประกันของคุณเหมือนกับการช้อปปิ้งบน e-commere พร้อมกับช่องทางการชำระเงินที่หลากหลาย ผ่อน 0% ได้อีกด้วย!",
                  en: "Shop insurance online just like you shop on e-commerce platform! We also provide many payment option with 0% installment.",
                },
                image: "/static/pages/landing/usp-key-1.svg",
              },
              {
                title: {
                  th: "ทำทุกอย่างได้ในแอป",
                  en: "Access all the cares\nin your hand.",
                },
                description: {
                  th: "เข้าถึงบริการต่างๆ ในซูเปอร์แอปฯ ได้อย่างเต็มรูปแบบ ทั้งปรึกษาแพทย์ออนไลน์, ตรวจสุขภาพกับ AI, รับสิทธิพิเศษจากพริวิเลจ โปรแกรม, เคลมออนไลน์, และอื่นๆ อีกมากมาย",
                  en: "Get the full access to many services on super app and enjoy Telemedicine, AI health checks, Privilege Program, Claim online, fun campaign and many more.",
                },
                image: "/static/pages/landing/usp-key-2.svg",
              },
              {
                title: {
                  th: "รักษา หรือซ่อมแซม\nโดยไม่ต้องสำรองจ่ายได้ทั่วประเทศ",
                  en: "Fixes and cures nationwide.",
                },
                description: {
                  th: "ดูแลทุกความเจ็บป่วย ซ่อมแซมทุกความ เสียหายได้ทันทีโดยไม่ต้องสำรองจ่าย ที่โรงพยาบาล หรือศูนย์ซ่อมบำรุงในเครือข่าย ทั่วประเทศ",
                  en: "All of your sickness will be cured and your broken things will be fixed at a nationwide network hospitals, garages, or repair centers without paying out of pockets.",
                },
                image: "/static/pages/landing/usp-key-3.svg",
              },
              {
                title: {
                  th: "เคลมออนไลน์ทันใจ\nรับเงินได้ในไม่กี่ขั้นตอน",
                  en: "Claim in few clicks away.",
                },
                description: {
                  th: "จะเคลม หรือเช็กสถานะการเคลม ก็สามารถ ทำได้ง่ายๆ ผ่านทางเว็บไซต์ หรือซูเปอร์แอปฯ ลืมเอกสารมากมายที่มีขั้นตอนวุ่นวายไปได้เลย",
                  en: "Submit or track your claims, you can do it all online. With just a few easy steps on our website or our super app. No more paperwork.",
                },
                image: "/static/pages/landing/usp-key-4.svg",
              },
              {
                title: {
                  th: "ชีวิตดีๆ กับสิทธิพิเศษมากมาย",
                  en: "Exclusive privilege\nonly for you.",
                },
                description: {
                  th: "รับสิทธิพิเศษมากมายจากพาร์ทเนอร์บนพริวิเลจโปรแกรมที่คัดสรรมาเพื่อช่วยให้คุณใช้ชีวิตได้ดียิ่งขึ้น ทั้งทาง ร่างกาย และจิตใจ",
                  en: "Enjoy our special offers from partner merchants on Privilege Program that will support you toward living healthily for your mind and body.",
                },
                image: "/static/pages/landing/usp-key-5.svg",
              },
              {
                title: {
                  th: "ซื้อประกันออนไลน์ได้ง่าย\nในราคาที่ใช่",
                  en: "Buy insurance online at a great price.",
                },
                description: {
                  th: "ซื้อประกัน และรับความคุ้มครองได้ตลอดเวลา ผ่านช่องทางออนไลน์ สะดวก รวดเร็ว และปลอดภัย ในราคาที่ใครก็เอื้อมถึง",
                  en: "You can get affordable, personalized coverage from the comfort of your couch. It's fast, simple, and secure to buy it online. ",
                },
                image: "/static/pages/landing/usp-key-6.svg",
              },
            ],
            content,
          },
          bannerProps: {
            image: "/static/pages/landing/retail-banner.svg",
            content,
          },
          partnerProps: {
            title: {
              en: "Our leading insurer partners.",
              id: "Mitra Asuransi Terkemuka Kami.",
            },
            subTitle: {
              en: "To give you peace of mind and confidence, we’ve teamed up with our leading insurer partners\nto create an insurance plan that brings you the best coverage and benefits.",
              id: "Untuk memberikanmu ketenangan pikiran dan kepercayaan diri, Sunday telah bekerja sama dengan mitra asuransi terkemuka untuk merancang paket asuransi yang memiliki pertanggungan dan manfaat terbaik untukmu.",
            },
            logos: [
              "/static/pages/landing/ksk-logo.svg",
              "/static/pages/landing/chubb-logo.svg",
              "/static/pages/landing/mbklife-logo.svg",
              "/static/pages/landing/tiplife-logo.svg",
              "/static/pages/landing/aia-logo.svg",
              "/static/pages/landing/lmg-logo.svg",
              "/static/pages/landing/msig-logo.svg",
              "/static/pages/landing/thaivivat-logo.svg",
              "/static/pages/landing/cigna-logo.svg",
            ],
            content,
          },
          channelConfig,
          channelCode,
          channelPath,
          sRefCode,
          isFromCarnival,
          ...(await serverSideTranslations(context.locale, [
            "main-page",
            "common",
            "layout",
            "drop-lead",
            "form",
            "otp-form",
            "technical-error-modal",
          ])),
        },
      };
    } catch (error) {
      const isUnknownError = checkUnknownError(error);

      if (!isUnknownError) {
        return {
          notFound: true,
        };
      }

      throw error;
    }
  };

export default HomePage;
