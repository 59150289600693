import { Card, Col, Row } from "antd";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { Headline, Body } from "sunday-component";
import { DesktopContainer, MobileContainer } from "../Common/Container";

const styles = {
  card: {
    body: {
      padding: "0",
    },
    container: {
      borderRadius: "16px",
      height: "100%",
      padding: "40px 24px",
      margin: "auto",
    },
  },
};

const CardContainer = styled(Col)`
  max-width: 439.33px;
  ${down("sm")} {
    max-width: 343px;
  }
  ${down("lg")} {
    max-width: 360px;
  }
  padding: 12px;
`;
const CardCoverStyle = styled("img")`
  object-fit: contain;
  max-width: 100%;
  height: 112px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const CardTitle = styled(Headline)`
  height: 58px;
  text-align: center;
  margin-top: 24px;
`;

const CardBody = styled(Body)`
  min-height: 96px;
  margin-top: 24px;
  text-align: center;
`;

export type USPCardComponentProp = {
  title: string;
  description: string;
  image: string;
};

const USPCardComponent = ({
  title,
  description,
  image,
}: USPCardComponentProp) => (
  <CardContainer xs={24} md={12} lg={8}>
    <Card
      bordered
      style={styles.card.container}
      bodyStyle={styles.card.body}
      cover={<CardCoverStyle src={image} alt="coverImage" />}
    >
      <Row>
        <Col span={24}>
          <DesktopContainer>
            <CardTitle level={4}>{title}</CardTitle>
            <CardBody level={6}>{description}</CardBody>
          </DesktopContainer>
          <MobileContainer>
            <CardTitle level={6}>{title}</CardTitle>
            <CardBody level={7}>{description}</CardBody>
          </MobileContainer>
        </Col>
      </Row>
    </Card>
  </CardContainer>
);
export default USPCardComponent;
