/* eslint-disable import/prefer-default-export */
import { LeadType } from "src/types/lead";
import { axiosErrorUnwrap } from "src/utils/axiosError";
import { clientHttpInstance } from "./http-client";

export const submitLead = async (lead: LeadType): Promise<void> => {
  try {
    const res = await clientHttpInstance.post<any>("/leads/submit", lead);
    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};

export type SendLeadOtp = {
  referenceNumber: string;
};

export const sendLeadOtp = async (
  mobileNo: string,
  sRefCode: string,
  lang: string
): Promise<SendLeadOtp> => {
  try {
    const res = await clientHttpInstance.post<any>(
      "/leads/send-otp",
      { mobileNo, sRefCode },
      {
        headers: {
          "Accept-Language": lang,
        },
      }
    );
    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};
