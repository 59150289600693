// code for general error.
export const ERROR_CODE = {
  // validation fail or input invalid.
  VALIDATION_ERROR: "GVE0001",

  // internal error.
  TECHNICAL_ERROR: "TE00001",
};

// code for business error.
export const BUSINESS_ERROR_CODE = {
  // attempt to find but not found
  NOT_FOUND: "BE00001",

  // expired or not active item.
  EXPIRED: "BE00002",

  // doesn't matched the given condition.
  NOT_MATCHED_WITH_CONDITION: "BE00003",

  // failed in amlo checking
  AMLO_ERROR: "BE00004",

  // pricing changed or invalid
  PRICING_INVALID: "BE00005",

  // coupon error
  COUPON_ERROR: "BE00006",

  // status is invalid to process, status is invalid
  STATUS_INVALID: "BE00007",

  // status is invalid to process, status is already cancel
  STATUS_CANCEL: "BE00008",

  // status is invalid to process, status is already paid
  STATUS_PAID: "BE00009",

  STATUS_RENEWED: "BE00010",

  // status is invalid to process, status is already reject
  STATUS_REJECT: "BE00011",

  // verify is invalid
  VERIFY_NOT_VALID: "BE00014",
};
