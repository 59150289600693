import { Col, Row } from "antd";
import { ReactElement } from "react";
import { down, up } from "styled-breakpoints";
import styled from "styled-components";
import { Property } from "csstype";
import { Hero as H, Body as Bd, Headline as Hl } from "sunday-component";
import { useRouter } from "next/router";

const BannerImg = styled.img`
  @media (min-width: 1432px) {
    width: 582px;
  }
  @media only screen and (max-width: 767px) {
    width: 254px;
    display: block;
    margin: auto;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: auto;
    height: 332px;
  }
  width: calc(100vw - 850px);
`;

const TextContainerMobile = styled.div`
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-top: 40px;
  }
  ${up("lg")} {
    display: none;
  }
`;

const TextContainerDesktop = styled.div`
  ${up("lg")} {
    position: absolute;
    top: 0;
    padding-top: 64px;
  }
  ${down("md")} {
    display: none;
  }
`;

const BannerImgContainer = styled.div`
  ${up("lg")} {
    position: absolute;
    bottom: 0;
    padding-bottom: 142px;
  }
  ${down("md")} {
    margin: 32px auto 24px auto;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin: 32px auto 24px 90px;
    overflow: hidden;
  }
`;

export type BannerProps = {
  image: string;
  content: any;
};

const Banner = ({ image, content }: BannerProps): ReactElement => {
  const { locale } = useRouter();
  const Body = styled(Bd)<{
    textAlign?: Property.TextAlign;
    lineHeight: string;
  }>`
    text-align: ${(props) => props.textAlign || "start"};
    color: ${content.bannerBodyTextColor};
    line-height: ${(props) => props.lineHeight};
  `;
  const Hero = styled(H)<{
    textAlign?: Property.TextAlign;
    lineHeight: string;
  }>`
    text-align: ${(props) => props.textAlign || "start"};
    margin-bottom: 16px;
    color: ${content.bannerTitleTextColor};
    line-height: ${(props) => props.lineHeight};
  `;
  const HeadLine = styled(Hl)<{
    textAlign?: Property.TextAlign;
    lineHeight: string;
  }>`
    margin-bottom: 16px;
    text-align: ${(props) => props.textAlign || "start"};
    color: ${content.bannerSubTitleTextColor};
    line-height: ${(props) => props.lineHeight};
  `;
  const CircleBackground = styled.div`
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      border-radius: 0;
      height: 364px;
      padding: 0px 0px 0px 24px;
    }
    ${down("sm")} {
      border-radius: 0% 0% 99% 99% / 0% 0% 10% 10%;
      height: 553px;
    }
    height: 680px;
    border-radius: 0% 0% 99% 99% / 0% 0% 20% 20%;
    background: ${content.bannerBgImageUrl};
    padding: 0px 24px;
  `;

  const ContentContainer = styled(Row)`
    max-width: var(--content-max-width);
    margin: auto;
    ${up("lg")} {
      height: 100%;
    }
    margin-right: 0 !important;
    margin-left: 0 !important;
  `;

  const Container = styled.div`
    max-width: var(--content-max-width);
    margin: auto;
    height: 100%;
  `;

  return (
    <>
      <CircleBackground>
        <Container>
          <ContentContainer gutter={[{ lg: 0, xl: 136, xxl: 136 }, 0]}>
            <Col
              xs={{ span: 24, order: 2 }}
              md={{ span: 11, order: 1 }}
              lg={{ span: 16, order: 1 }}
              xl={{ span: 12, order: 1 }}
            >
              <TextContainerMobile>
                <Hero level={4} lineHeight="41px">
                  {content.bannerTitle[locale]}
                </Hero>
                <HeadLine level={5} lineHeight="26px">
                  {content.bannerSubtitle[locale]}
                </HeadLine>
                <Body level={7} lineHeight="23px">
                  {content.bannerBody[locale]}
                </Body>
              </TextContainerMobile>
              <TextContainerDesktop>
                <Hero level={2} lineHeight="72px">
                  {content.bannerTitle[locale]}
                </Hero>
                <HeadLine level={3} lineHeight="31px">
                  {content.bannerSubtitle[locale]}
                </HeadLine>
                <Body level={5} lineHeight="28px">
                  {content.bannerBody[locale]}
                </Body>
              </TextContainerDesktop>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 13, order: 2 }}
              lg={{ span: 8, order: 2 }}
              xl={{ span: 12, order: 2 }}
            >
              <BannerImgContainer>
                <BannerImg src={content.bannerImageUrl} />
              </BannerImgContainer>
            </Col>
          </ContentContainer>
        </Container>
      </CircleBackground>
    </>
  );
};
export default Banner;
