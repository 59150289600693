import { Col, Image, Row } from "antd";
import { CheckBoxErrorText } from "./CheckBoxForm";

const styles = {
  alertIcon: {
    height: "24px",
    width: "24px",
    marginRight: "10px",
  },
  errorMessage: {
    margin: "8px 0px 0px",
  },
};

const ErrorMessageWithIcon = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <Row wrap={false} style={styles.errorMessage}>
      <Col flex="24px">
        <Image
          src="/static/pages/applicationPage/consent-alert-icon.svg"
          preview={false}
          style={styles.alertIcon}
        />
      </Col>
      <Col flex="auto">
        <CheckBoxErrorText level={7}>{errorMessage}</CheckBoxErrorText>
      </Col>
    </Row>
  );
};

export default ErrorMessageWithIcon;
